/* global TweenLite*/

// =====================================================
// Polyfill forEach for IOS 9
// Reference: http://es5.github.io/#x15.4.4.18
// =====================================================
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function(callback/*, thisArg*/) {
    var T, k;
    if (this == null) {
      throw new TypeError('this is null or not defined');
    }
    var O = Object(this);
    var len = O.length >>> 0;
    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' is not a function');
    }
    if (arguments.length > 1) {
      T = arguments[1];
    }
    k = 0;
    while (k < len) {

      var kValue;
      if (k in O) {
        kValue = O[k];
        callback.call(T, kValue, k, O);
      }
      k++;
    }
  };
}
// =====================================================
// HOME PAGE JAVASCRIPT
// =====================================================
(function homePage() {
  const smoothers = [].slice.call(document.querySelectorAll('.smoother'));
  const mobileSmoothers = [].slice.call(document.querySelectorAll('.mobileSmoother'));

  // If coming from another page, scroll to element if necessary
  function scrollDirectlyTo() {
    const comingFromOtherPage = (window.location.search).indexOf('?scrollto=') >= 0;
    if (comingFromOtherPage) {
      const link = window.location.search.slice(-1);
      if (link === 'a') {
        setTimeout(() => {
          document.querySelector('.smoother[data-scrollto="#was"]').click();
        }, 400);
      } else if (link === 'b') {
        document.querySelector('.smoother[data-scrollto="#wie"]').click();
      } else if (link === 'c') {
        document.querySelector('.smoother[data-scrollto="#warum"]').click();
      }
    }
  }


  function addActiveClass() {
    const wasNav = document.querySelector('.smoother[data-scrollto="#was"]');
    const wieNav = document.querySelector('.smoother[data-scrollto="#wie"]');
    const warumNav = document.querySelector('.smoother[data-scrollto="#warum"]');
    const wasElement = document.querySelector('#was');
    const wieElement = document.querySelector('#wie');
    const warumElement = document.querySelector('#warum');

    const scrollY = window.scrollY + 300;

    if (scrollY < (wasElement.offsetTop + wasElement.clientHeight)) {
      wieNav.classList.remove('active');
      warumNav.classList.remove('active');
      wasNav.classList.add('active');
      // console.log('was active class');
    } else {
      wasNav.classList.remove('active');
    }

    if ((scrollY > wasElement.offsetTop + (wasElement.clientHeight)) && (scrollY < wieElement.offsetTop) + (wieElement.clientHeight / 2)) {
      // console.log('wie active class');
      wasNav.classList.remove('active');
      warumNav.classList.remove('active');
      wieNav.classList.add('active');
    }
    if (scrollY > warumElement.offsetTop) {
      // console.log('warum active class');
      wasNav.classList.remove('active');
      wieNav.classList.remove('active');
      warumNav.classList.add('active');
    }
  }
  window.addEventListener('load', scrollDirectlyTo);
  window.addEventListener('scroll', addActiveClass);
  // =====================================================
  // Smoothscroll
  // =====================================================

  function scrollToElement(e) {
    e.preventDefault();
    document.querySelector('body').classList.remove('menu-open');
    window.removeEventListener('scroll', addActiveClass);
    sliderImages.forEach((sliderImage) => {
      sliderImage.classList.add('active');
    });
    TweenLite.to(window, 1.5, {
      // scrollTo: this.getAttribute('data-scrollto'),
      scrollTo: { y: this.getAttribute('data-scrollto'), offsetY: 110 },
      ease: Power3.easeInOut,
      onComplete() {
        window.addEventListener('scroll', addActiveClass);
      },
    });
  }
  function addCorrectActiveClass() {
    smoothers.forEach((el) => {
      el.classList.remove('active');
    });
    mobileSmoothers.forEach((el) => {
      el.classList.remove('active');
    });
    this.classList.add('active');
  }

  smoothers.forEach((element) => {
    element.addEventListener('click', scrollToElement);
    element.addEventListener('click', addCorrectActiveClass);
  });
  mobileSmoothers.forEach((element) => {
    element.addEventListener('click', scrollToElement);
    element.addEventListener('click', addCorrectActiveClass);
  });


  // =====================================================
  // Slide in scroll for round images
  // =====================================================
  const sliderImages = document.querySelectorAll('.round .image');

  function debounce(func, wait = 20, immediate = true) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments; // eslint-disable-line prefer-rest-params
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function checkRound() {
    sliderImages.forEach((sliderImage) => {
      const slideInAt = (window.scrollY + window.innerHeight) - (sliderImage.clientHeight / 2);
      const imageBottom = sliderImage.offsetTop + sliderImage.clientHeight;
      const isHalfShown = slideInAt > sliderImage.offsetTop;
      const isNotScrolledPast = window.scrollY < imageBottom;
      if (isHalfShown && isNotScrolledPast) {
        sliderImage.classList.add('active');
      } else {
        // sliderImage.classList.remove('active');
      }
    });
  }
  window.addEventListener('scroll', debounce(checkRound));
}());
